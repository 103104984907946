import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CloseIcon from '@material-ui/icons/Close';

import { templateStyle } from '../../Styles/Components/templateStyle.js';

import DrawerList from './DrawerList.js';

import * as CONFIG from '../../config.js';

function SideDrawer(props) {
  const useStyles = makeStyles(templateStyle);
  const classes = useStyles();

  return (
    <Drawer
      variant="persistent"
      classes={{
        paper: clsx(classes.drawerPaper, !props.open && classes.drawerPaperClose),
      }}
      open={props.open}
    >
      <div className="group">
        <Typography component="div" className={classes.drawerLogo} variant="h5">
          <img alt={CONFIG.APP_NAME} src={process.env.PUBLIC_URL + '/assets/images/icons/app-icon-96x96.png'} className={classes.logo} />
          &nbsp;&nbsp;
          {CONFIG.APP_NAME}
        </Typography>
        <div className={classes.toolbarIcon}>
          <IconButton onClick={props.handleDrawerClose}>
            <CloseIcon />
          </IconButton>

          {/* <Typography variant="caption" display="block" gutterBottom>
            close
          </Typography> */}
        </div>
      </div>
      <Divider />
      <div align="center" className={classes.divUser}>
        {
          props.userData.picture ?
            <Avatar alt="Profile Picture" src={CONFIG.BACK_END_URL + CONFIG.PROFILE_PICTURE_PATH + "/" + props.userData.picture} className={classes.avatar} />
            :
            <Avatar className={classes.avatar}>
              {
                props.userData.name ?
                  props.userData.name.substr(0, 1)
                  :
                  <AccountCircleIcon />
              }
            </Avatar>
        }

        <Typography><strong>{props.userData.name}</strong></Typography>
      </div>
      <Divider />
      <DrawerList />
    </Drawer>
  );
}

const mapStateToProps = (state, ownProps) => {
  return Object.assign({}, ownProps, { userData: state.userData });
};

export default connect(mapStateToProps)(SideDrawer);