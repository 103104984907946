import React, { lazy } from 'react'
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';

const Test = lazy(() => import('./Pages/Test.js'));
const NotFound404 = lazy(() => import('./Pages/404.js'));
const SignIn = lazy(() => import('./Pages/SignIn.js'));
const ForgotPassword = lazy(() => import('./Pages/ForgotPassword.js'));
const ResetPassword = lazy(() => import('./Pages/ResetPassword.js'));

const Dashboard = lazy(() => import('./Pages/Dashboard.js'));
const ProjectIndex = lazy(() => import('./Pages/Project/Index.js'));
const ProjectAdd = lazy(() => import('./Pages/Project/Add.js'));
const ProjectEdit = lazy(() => import('./Pages/Project/Edit.js'));
const FinancialAnalysis = lazy(() => import('./Pages/Project/FinancialAnalysis/Index.js'));
const TechnicalDesign = lazy(() => import('./Pages/Project/TechnicalDesign/Index.js'));
const FaOtherSystems = lazy(() => import('./Pages/Project/FinancialAnalysis/System/Other/Index.js'));
const FaOtherSystem = lazy(() => import('./Pages/Project/FinancialAnalysis/System/Other/Details.js'));
const FaExistingSystem = lazy(() => import('./Pages/Project/FinancialAnalysis/System/Existing/Index.js'));
const FaPVSystem = lazy(() => import('./Pages/Project/FinancialAnalysis/System/PV/Index.js'));
const FACalculationCashFlow = lazy(() => import('./Pages/Project/FinancialAnalysis/Calculation/CashFlow.js'));
const FACalculationCostSaving = lazy(() => import('./Pages/Project/FinancialAnalysis/Calculation/CostSaving.js'));
const FACalculationLCOE = lazy(() => import('./Pages/Project/FinancialAnalysis/Calculation/LCOE.js'));
const AccountIndex = lazy(() => import('./Pages/Account/Index.js'));
const SettingIndex = lazy(() => import('./Pages/Setting/Index.js'));

function authComponent(isLoggedIn, langPath) {
  if (isLoggedIn === false) return <Redirect to={langPath + "/sign-in"} />;
  else return null;
}

function Routes(props) {
  const { i18n } = useTranslation();
  const langPath = "/" + i18n.language;
  const checkResultAuth = authComponent(props.isLoggedIn, langPath);
  return (
    <Switch>
      <Route exact
        path={langPath + "/project/system/other/:id([0-9]+)/edit/:system_order([0-9]+)"}
        component={(routeProps) => checkResultAuth ? checkResultAuth : <FaOtherSystem {...routeProps} />}
      />
      <Route exact
        path={langPath + "/project/financial-analysis/calculation/cash-flow/:id([0-9]+)"}
        component={(routeProps) => checkResultAuth ? checkResultAuth : <FACalculationCashFlow {...routeProps} />}
      />
      <Route exact
        path={langPath + "/project/financial-analysis/calculation/cost-saving/:id([0-9]+)"}
        component={(routeProps) => checkResultAuth ? checkResultAuth : <FACalculationCostSaving {...routeProps} />}
      />
      <Route exact
        path={langPath + "/project/financial-analysis/calculation/lcoe/:id([0-9]+)"}
        component={(routeProps) => checkResultAuth ? checkResultAuth : <FACalculationLCOE {...routeProps} />}
      />
      <Route exact
        path={langPath + "/project/system/other/:id([0-9]+)/add"}
        component={(routeProps) => checkResultAuth ? checkResultAuth : <FaOtherSystem {...routeProps} />}
      />
      <Route exact
        path={langPath + "/project/system/other/:id([0-9]+)"}
        component={(routeProps) => checkResultAuth ? checkResultAuth : <FaOtherSystems {...routeProps} />}
      />
      <Route exact
        path={langPath + "/project/system/existing/:id([0-9]+)"}
        component={(routeProps) => checkResultAuth ? checkResultAuth : <FaExistingSystem {...routeProps} />}
      />
      <Route exact
        path={langPath + "/project/system/pv/:id([0-9]+)"}
        component={(routeProps) => checkResultAuth ? checkResultAuth : <FaPVSystem {...routeProps} />}
      />
      <Route exact
        path={langPath + "/project/financial-analysis/:id([0-9]+)"}
        component={(routeProps) => checkResultAuth ? checkResultAuth : <FinancialAnalysis {...routeProps} />}
      />
      <Route exact
        path={langPath + "/project/technical-design/:id([0-9]+)"}
        component={(routeProps) => checkResultAuth ? checkResultAuth : <TechnicalDesign {...routeProps} />}
      />
      <Route exact
        path={langPath + "/project/edit/:id([0-9]+)"}
        component={(routeProps) => checkResultAuth ? checkResultAuth : <ProjectEdit {...routeProps} />}
      />
      <Route exact
        path={langPath + "/project/add"}
        component={(routeProps) => checkResultAuth ? checkResultAuth : <ProjectAdd {...routeProps} />}
      />
      <Route exact
        path={langPath + "/projects"}
        component={() => checkResultAuth ? checkResultAuth : <ProjectIndex />}
      />
      <Route exact
        path={langPath + "/account"}
        component={() => checkResultAuth ? checkResultAuth : <AccountIndex />}
      />
      <Route exact
        path={langPath + "/settings"}
        component={() => checkResultAuth ? checkResultAuth : <SettingIndex />}
      />
      <Route exact
        path={langPath + "/reset-password/:hash([a-z0-9]+)"}
        component={(routeProps) => <ResetPassword checkAuth={props.checkAuth} {...routeProps} />}
      />
      <Route exact
        path={langPath + "/forgot-password"}
        component={() => <ForgotPassword />}
      />
      <Route exact
        path={langPath + "/sign-in"}
        component={(routeProps) => <SignIn {...routeProps} />}
      />
      <Route exact
        path={langPath + "/test"}
        component={Test}
      />
      <Route exact
        path={langPath + "/404"}
        component={NotFound404}
      />
      <Route exact
        path={langPath}
        component={(routeProps) => checkResultAuth ? checkResultAuth : <Dashboard {...routeProps} />}
      />
      <Route exact
        path="/"
        component={() => <Redirect to={langPath} />}
      />
      <Route component={NotFound404} />
    </Switch>
  );
}

function mapStateToProps(state, ownProps) {
  return Object.assign({}, ownProps, { isLoggedIn: state.isLoggedIn });
};

export default connect(mapStateToProps)(Routes);