import { idbUpdateData } from '../Helper/idbHandler.js';
import * as CONFIG from '../config.js';

export function getCookie(name) {
  var cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}
export function isWindowXXS() {
  return window.innerWidth < 360;
}
export function isWindowXS() {
  return window.innerWidth < 600;
}
export function isWindowSM() {
  var winWidth = window.innerWidth;
  return (winWidth > 599 && winWidth < 960);
}
export function isWindowMD() {
  var winWidth = window.innerWidth;
  return (winWidth > 959 && winWidth < 1280);
}
export function isWindowLG() {
  var winWidth = window.innerWidth;
  return (winWidth > 1279 && winWidth < 1920);
}
export function isWindowXL() {
  return window.innerWidth > 1919;
}
export function isArray(value) {
  return value && value !== undefined && typeof value === 'object' && value.constructor === Array;
}
export function isObject(value) {
  return value && value !== undefined && typeof value === 'object' && value.constructor === Object;
}
export function isEmptyArray(arr) {
  let isEmpty = true;
  if (isArray(arr) && arr.length > 0) isEmpty = false;
  return isEmpty;
}
export function isEmptyObject(object) {
  let isEmpty = true;
  if (isObject(object)) {
    for (let prop in object) {
      if (object.hasOwnProperty(prop)) {
        isEmpty = false;
        break
      }
    }
  }
  return isEmpty;
}
export function isEmptyVar(vari) {
  if (typeof vari === "undefined" || vari === undefined || vari === null || vari === "") return true;
  return false;
}
export function isArrayEqual(arr1, arr2, isStrict) {
  if (isArray(arr1) && isArray(arr2)) {
    if (arr1.length === arr2.length) {
      const arrLength = arr1.length;
      let isSame = true;
      for (let i = 0; i < arrLength; i++) {
        if (isStrict) {
          if (arr1[i] !== arr2[i]) {
            isSame = false;
            break;
          }
        }
        else {
          if (arr1[i] != arr2[i]) {
            isSame = false;
            break;
          }
        }
      }
      return isSame;
    }
    else return false;
  }
  else return false;
}
//
export function arrSum(total, current) {
  if (current === "" || current === null) current = 0;
  else current = parseFloat(current);
  total = parseFloat(total);
  return total + current;
}
export function countSubTotalCapitalReplacementDetail(lifetime, crdPrice, crdQty, crdLifespan) {
  let crdSubTotal = 0;
  if (lifetime !== "" && lifetime > 0) {
    if (parseFloat(crdPrice) > 0 && parseInt(crdQty) > 0 && parseInt(crdLifespan) > 0) {
      crdSubTotal = parseFloat(parseFloat(crdPrice) * parseInt(crdQty) * Math.ceil(parseInt(lifetime) / parseInt(crdLifespan)));
    }
  }
  return crdSubTotal;
}
export function createFormDataFromState(dataObj, exception, only) {
  let postFormData = new URLSearchParams(), tempVal, i, j;
  if (!isArray(exception)) exception = [];
  if (!isArray(only)) only = [];
  Object.keys(dataObj).forEach(function (key, index) {
    if (
      exception.indexOf(key) < 0 &&
      (
        (!isEmptyArray(only) && only.indexOf(key) >= 0) || isEmptyArray(only)
      )
    ) {
      tempVal = dataObj[key];
      if (isArray(tempVal))
        for (i = 0; i < tempVal.length; i++) {
          if (isArray(tempVal[i]))
            for (j = 0; j < tempVal[i].length; j++) postFormData.append(key + "[" + i + "][" + j + "]", tempVal[i][j]);
          else
            postFormData.append(key + "[" + i + "]", tempVal[i]);
        }
      else
        postFormData.append(key, tempVal);
    }
  });
  return postFormData;
}
export function initStateArray(arrTemp, count, defValue) {
  if (typeof defValue === 'undefined' || defValue === undefined) defValue = "";
  count = parseInt(count);
  if (count > 0) {
    let i;
    if (isEmptyArray(arrTemp)) {
      for (i = 0; i < count; i++) arrTemp[i] = defValue;
    }
    else {
      let countData = parseInt(arrTemp.length);
      if (countData === count) {
        return arrTemp;
      }
      else if (countData > count) {
        arrTemp.length = count;
      }
      else {
        let tempStore = arrTemp;
        for (i = countData; i < count; i++) tempStore[i] = defValue;
        arrTemp = tempStore;
      }
    }
  }
  else arrTemp = [];

  return arrTemp;
}
export function parseArrFloat(arr) {
  for (let i = 0; i < arr.length; i++) arr[i] = parseFloat(arr[i]);
  return arr;
}
export function removeArrElem(arr, idx, count) {
  if (idx > -1 && idx <= (arr.length - 1) && count <= arr.length) {
    arr.splice(idx, count);
    return arr;
  }
}
export function setEmptyStringIfUndefined(cekValue) {
  if (typeof cekValue === 'undefined' || cekValue === undefined || cekValue === null) return "";
  else return cekValue;
}
export function setStateArray(arrTemp, elemId, elemValue, sameIdx) {
  let adjustment = 1;
  if (typeof sameIdx !== 'undefined' && sameIdx !== undefined && sameIdx !== null && sameIdx !== '' && sameIdx) adjustment = 0
  let arrIdString = elemId.split("_");
  const idx = parseInt(arrIdString[(arrIdString.length) - 1]) - adjustment;
  arrTemp[idx] = elemValue;
  return arrTemp;
}
export function setThousandGroup($currCode) {
  if (CONFIG.THOUSAND_GROUP_LAKH.includes($currCode.currency_code)) return "lakh";
  if (CONFIG.THOUSAND_GROUP_WAN.includes($currCode.currency_code)) return "wan";
  return CONFIG.DEFAULT_THOUSAND_FORMAT;
}
export function sumReplacementCost(type, arrVal) {
  let i, j, k, val = [], arvalLength = arrVal.length, arvaliLength;
  for (i = 0; i < arvalLength; i++) {
    if (i === 0) val[0] = 0;
    j = i + 1;
    arvaliLength = arrVal[i].length
    for (k = 0; k < arvaliLength; k++) {
      switch (type) {
        case "int": arrVal[i][k] = parseInt(arrVal[i][k]); break;
        case "float": arrVal[i][k] = parseFloat(arrVal[i][k]); break;
        default: break;
      }
    }
    val[j] = arrVal[i].reduce(arrSum, 0);
  }
  if (!isEmptyArray(val)) {
    let total = val.reduce(arrSum, 0);
    val[0] = total;
  }

  return val;
}
export function updateIdb(tableName, dataObj, dataId, projectId) {
  if (typeof projectId === "undefined" || projectId === undefined) projectId = dataId;
  var newObj = Object.assign({}, dataObj, { id: dataId, project_id: projectId });
  idbUpdateData(tableName, newObj);
}