import * as ACTIONS from "../constants/action-types.js";

const initialState = {
  isLoggedIn: null,
  userData: {},
  userLocale: {},
  pageData: {
    title: "",
    appBarTitle: ""
  }
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SET_LOGGED_IN:
      return Object.assign({}, state, { isLoggedIn: action.payload });
    case ACTIONS.SET_USER_DATA:
      return Object.assign({}, state, { userData: Object.assign({}, action.payload) });
    case ACTIONS.SET_USER_LOCALE:
      return Object.assign({}, state, { userLocale: Object.assign({}, action.payload) });
    case ACTIONS.SET_PAGE_DATA:
      return Object.assign({}, state, { pageData: Object.assign({}, action.payload) });
    default:
      break;
  }
  return state;
};

export default rootReducer;