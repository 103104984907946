import React from 'react';
import Typography from '@material-ui/core/Typography';
import * as CONFIG from '../config.js';

export default function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      &#169;&nbsp;{CONFIG.APP_NAME}&nbsp;{new Date().getFullYear()}
    </Typography>
  );
}