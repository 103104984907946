import React, { useState } from 'react';
import clsx from 'clsx';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';

import { templateStyle } from '../Styles/Components/templateStyle.js';

import SideDrawer from './Drawer/SideDrawer.js';
import Copyright from './Copyright.js';

import { isWindowMD, isWindowLG, isWindowXL } from '../Helper/main.js';
import * as CONFIG from '../config.js';

function Template(props) {
  const useStyles = makeStyles(templateStyle);
  const classes = useStyles();
  const [open, setOpen] = useState(isWindowMD() || isWindowLG() || isWindowXL());
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Helmet>
        <title>
          {
            props.pageData.title !== "" ?
              props.pageData.title + " | "
              :
              null
          }
          {CONFIG.APP_NAME}
        </title>
      </Helmet>
      <AppBar position="fixed" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          {/*
          //sama aja dengan di bawah nya
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          */}
          {
            !open ?
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              :
              null
          }
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {props.pageData.appBarTitle}
          </Typography>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <SideDrawer open={open} handleDrawerClose={handleDrawerClose} />
      <main className={clsx(classes.content, open && classes.contentShift)}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>{props.children}</Container>
        <footer className={classes.footer}>
          <Container maxWidth="sm">
            <Copyright />
          </Container>
        </footer>
      </main>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return Object.assign({}, ownProps, { pageData: state.pageData });
};

export default connect(mapStateToProps)(Template);