import { openDB } from 'idb';
import { isArray, isObject } from './main.js';

const IDB_NAME = 'idb_oases_prototype';
const IDB_TABLES = [
  'cache_nasa_data',
  'draft_technical_designs',
  'financial_analyses',
  'financial_analysis_existing',
  'financial_analysis_other',
  'financial_analysis_other_system',
  'financial_analysis_pv',
  'financial_analysis_lcoe',
  'financial_analysis_cash_flow',
  'financial_analysis_cost_saving',
  'language',
  'logout_token',
  'project_list',
  'projects',
  'technical_designs',
  'user_data',
  'test_table'
];

async function connectIDB() {
  return openDB(IDB_NAME, 1, {
    upgrade(db) {
      IDB_TABLES.forEach(function (val) {
        let store = db.createObjectStore(val, {
          keyPath: 'id',
          autoIncrement: true
        });
        if (val === 'project_list')
          store.createIndex('updated_at', 'updated_at');
      });
    }
  });
}
export async function idbStoreData(tableName, data) {
  let idb = await connectIDB();
  const checkTypeObj = isObject(data), checkTypeArr = isArray(data);
  if (checkTypeArr || checkTypeObj) {
    if (checkTypeObj) return idb.add(tableName, data);
    else if (checkTypeArr) {
      const tx = idb.transaction(tableName, 'readwrite');
      data.forEach(function (val) {
        tx.store.add(val);
      });
      return tx.done;
    }
  }
}
export async function idbGetAllData(tableName, index) {
  let idb = await connectIDB();
  if (typeof index !== 'undefined' && index !== undefined && index !== null && index !== '' && index) {
    return idb.getAllFromIndex(tableName, index);
  }
  else {
    return idb.getAll(tableName);
  }
}
export async function idbGetData(tableName, key) {
  let idb = await connectIDB();
  return idb.get(tableName, key);
}
export async function idbUpdateData(tableName, newData) {
  let idb = await connectIDB();
  return idb.put(tableName, newData);
}
export async function idbDeleteData(tableName, key) {
  let idb = await connectIDB();
  if (isArray(key)) {
    for (let i = 0; i < key.length; i++) {
      idb.delete(tableName, key[i]);
    }
    return Promise.resolve("Done");
  }
  else return idb.delete(tableName, key);
}
export async function idbClearStore(tableName) {
  let idb = await connectIDB();
  return await idb.clear(tableName);
}