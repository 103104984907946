export const APP_NAME = "Oases Prototype";
export const APP_DEBUG = true;
export const DEFAULT_LOCALE = "en";
export const DEFAULT_CURRENCY_SYMBOL = "$";
export const DEFAULT_THOUSAND_FORMAT = "thousand";
export const DEFAULT_THOUSAND_SEPARATOR = ",";
export const DEFAULT_DECIMAL_SEPARATOR = ".";
export const THOUSAND_GROUP_LAKH = [
  'INR',
  'NPR',
  'PKR',
  'LKR',
  'BDT',
  'BTN',
  'MVR'
];
export const THOUSAND_GROUP_WAN = [
  'CNY'
];
export const BACK_END_URL = "https://oases-next-be.webtech.id";
export const PROFILE_PICTURE_PATH = "/images/user/profile_pictures";
export const PROFILE_PICTURE_MIN_RES = 160;
export const PROFILE_PICTURE_MAX_SIZE = 2097152;
export const PROFILE_PICTURE_MAX_SIZE_STR = "2MB";

export const RECAPTCHA_CLIENT_KEY = "6LcY4OUUAAAAAEJ7T1Maim4tMOsL353-m3hj5_7p";
export const TINY_API_KEY = "2i66jnntciaxb04qv9jw9fle08o11yeo45pgz48mndjbxiub";
export const TINY_INIT_DATA = {
  height: "480",
  menu: {
    format: {
      title: "Format",
      items: "alignleft aligncenter alignright alignjustify bold italic underline strikethrough blockquote subscript superscript forecolor backcolor"
    }
  },
  plugins: 'charmap directionality fullscreen link lists paste searchreplace table',
  toolbar: 'paste searchreplace| alignleft aligncenter alignright alignjustify bold italic underline strikethrough blockquote subscript superscript forecolor backcolor fontsizeselect | outdent indent | ltr rtl | numlist bullist | charmap link | table | fullscreen',
  menubar: 'edit insert table view',
  toolbar_mode: 'floating'
};

export const PATTERN_PASSWORD = "(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}";