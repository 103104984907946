import React from 'react';
import { withRouter } from 'react-router-dom';
import * as CONFIG from './config.js';

function checkIsLangExist(availableLanguage, lang) {
  let isExist = false;
  let loopLength = availableLanguage.length;
  for (let i = 0; i < loopLength; i++)
    if (lang === availableLanguage[i].code) {
      isExist = true;
      break;
    }
  return isExist;
}

function getStoredLocale(availableLanguage) {
  let returnLocale = "";
  if (localStorage.getItem("i18nextLng")) returnLocale = localStorage.getItem("i18nextLng");
  if (returnLocale.length > 2) returnLocale = returnLocale.substr(0, 2);
  returnLocale = returnLocale.toLowerCase();

  if (checkIsLangExist(availableLanguage, returnLocale)) return returnLocale;
  return "";
}
function getLocaleFromPath(availableLanguage, path) {
  let returnLocale = getStoredLocale(availableLanguage);
  if (!returnLocale) returnLocale = CONFIG.DEFAULT_LOCALE;

  if (path !== "" && path !== "/") {
    const pathLocale = path.split('/')[1];
    if (pathLocale && checkIsLangExist(availableLanguage, pathLocale.toLowerCase())) returnLocale = pathLocale;
  }

  returnLocale = returnLocale.toLowerCase();
  return returnLocale;
}

class Localizer extends React.Component {
  constructor(props) {
    super(props)
    const { availableLanguage } = this.props;
    this.setLocale(getLocaleFromPath(availableLanguage, this.props.location.pathname));
    this.props.history.listen(location => {
      this.setLocale(getLocaleFromPath(availableLanguage, location.pathname));
    })
  }
  setLocale(newLocale) {
    const { availableLanguage, changeLanguage } = this.props;
    if (newLocale !== getStoredLocale(availableLanguage)) changeLanguage(newLocale);
  }
  render() {
    return this.props.children;
  }
}

export default withRouter(Localizer);