import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { connect } from 'react-redux';
import { setLoggedIn, setUserData, setUserLocale } from './redux/actions/index.js';

import { useTranslation } from 'react-i18next';
import withRoot from './Components/withRoot.js';
import { useTheme } from '@material-ui/core/styles';

import Template from './Components/Template.js';
import Localizer from './Localizer.js';
import Routes from './Routes.js';

import { fetchHandler } from './Helper/fetchHandler.js';
import { idbClearStore, idbStoreData } from './Helper/idbHandler.js';
import { isEmptyObject } from './Helper/main.js';
import * as CONFIG from './config.js';

import './css/app.css';

function App(props) {
  const theme = useTheme();
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();
  theme.direction = i18n.dir();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    document.body.dir = i18n.dir();
    theme.direction = i18n.dir();
  };

  const [languageData, setLanguageData] = useState([]);

  const getLanguageList = (setUserDataObj) => {
    fetchHandler(
      "GET",
      CONFIG.BACK_END_URL + "/api/language",
      true
    )
      .then(getResponse => {
        if (getResponse.status === 200) {
          getResponse.json()
            .then(jsonResponse => {
              setLanguageData(jsonResponse);
              idbClearStore('language').then(() => {
                idbStoreData('language', jsonResponse).then(() => {
                  idbClearStore('user_data').then(() => {
                    let tempUserData = { ...setUserDataObj };
                    delete tempUserData.isLoggedIn;
                    if (!isEmptyObject(tempUserData)) {
                      const userLocaleObj = {
                        language_code: setUserDataObj.language_code,
                        /* currency_code: setUserDataObj.currency_code,
                        currency_symbol: setUserDataObj.currency_symbol,
                        thousand_separator: setUserDataObj.thousand_separator,
                        decimal_separator: setUserDataObj.decimal_separator */
                      };
                      const userDataObj = {
                        name: setUserDataObj.name,
                        picture: setUserDataObj.picture
                      };
                      idbStoreData('user_data', Object.assign({}, tempUserData, { id: 1 })).then(() => {
                        props.setUserData(userDataObj);
                        props.setUserLocale(userLocaleObj);
                      });
                    }
                    props.setLoggedIn(setUserDataObj.isLoggedIn);
                  });
                });
              });
            });
        }
      });
  };

  const checkAuth = (force = false) => {
    if (isEmptyObject(props.userData) || force) {
      fetchHandler(
        "GET",
        CONFIG.BACK_END_URL + "/api/check-auth",
        true
      )
        .then(getResponse => {
          if (getResponse.status === 200) {
            getResponse.json()
              .then(jsonResponse => {
                getLanguageList(Object.assign({}, jsonResponse, { isLoggedIn: true }));
              });
          }
          else {
            fetch(CONFIG.BACK_END_URL + "/sanctum/csrf-cookie", {
              method: 'GET',
              mode: 'cors',
              credentials: 'include'
            })
              .then(getSanctumResponse => {
                if (getSanctumResponse.status === 204)
                  return Promise.reject(new Error("User Error: " + getResponse.status));
                else
                  return Promise.reject(new Error("Sanctum Error: " + getSanctumResponse.status));
              })
              .catch(errSanc => {
                if (CONFIG.APP_DEBUG) console.log(errSanc);
                getLanguageList({ isLoggedIn: false });
              });
          }
        })
        .catch(err => {
          if (CONFIG.APP_DEBUG) console.log("Check Auth Error: " + err);
          getLanguageList({ isLoggedIn: false });
        });
    }
  }
  useEffect(() => {
    checkAuth();
    //return () => { };
  }, []);

  if (props.isLoggedIn === null) return <>Loading...</>;
  else {
    const supportsHistory = 'pushState' in window.history;
    const varRoutes = <Routes checkAuth={checkAuth} />;
    return (
      <Router forceRefresh={!supportsHistory}>
        <Localizer availableLanguage={languageData} changeLanguage={changeLanguage}>
          {
            props.isLoggedIn ?
              <Template>{varRoutes}</Template>
              :
              varRoutes
          }
        </Localizer>
      </Router>
    );
  }
}

const mapStateToProps = state => {
  return { isLoggedIn: state.isLoggedIn };
};
const mapDispatchToProps = dispatch => {
  return {
    setLoggedIn: isLoggedIn => dispatch(setLoggedIn(isLoggedIn)),
    setUserData: userData => dispatch(setUserData(userData)),
    setUserLocale: userLocale => dispatch(setUserLocale(userLocale))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRoot(App));