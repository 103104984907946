import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setLoggedIn, setUserData, setUserLocale } from '../../redux/actions/index.js';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

import { fetchHandler } from '../../Helper/fetchHandler.js';
import { idbClearStore } from '../../Helper/idbHandler.js';
import * as CONFIG from '../../config.js';

function SignOut(props) {
  const history = useHistory();
  const { t } = useTranslation('drawer');

  const logout = () => {
    fetchHandler(
      "POST",
      CONFIG.BACK_END_URL + "/logout",
      true
    )
      .then(getResponse => {
        if (getResponse.status === 204) {
          idbClearStore('cache_nasa_data')
            .then(function () {
              return idbClearStore('draft_technical_designs');
            })
            .then(function () {
              return idbClearStore('financial_analyses');
            })
            .then(function () {
              return idbClearStore('financial_analysis_existing');
            })
            .then(function () {
              return idbClearStore('financial_analysis_other');
            })
            .then(function () {
              return idbClearStore('financial_analysis_other_system');
            })
            .then(function () {
              return idbClearStore('financial_analysis_pv');
            })
            .then(function () {
              return idbClearStore('financial_analysis_lcoe');
            })
            .then(function () {
              return idbClearStore('financial_analysis_cash_flow');
            })
            .then(function () {
              return idbClearStore('financial_analysis_cost_saving');
            })
            .then(function () {
              return idbClearStore('projects');
            })
            .then(function () {
              return idbClearStore('project_list');
            })
            .then(function () {
              return idbClearStore('technical_designs');
            })
            .then(function () {
              return idbClearStore('user_data');
            })
            .then(function () {
              return idbClearStore('test_table');
            })
            .then(function () {
              props.setUserData({});
              props.setUserLocale({});
              props.setLoggedIn(false);
              history.push('/');
            });
        }
      });
  }
  return (
    <List>
      <ListItem onClick={logout} button>
        <ListItemIcon>
          <PowerSettingsNewIcon />
        </ListItemIcon>
        <ListItemText primary={t("signout")} />
      </ListItem>
    </List>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    setLoggedIn: isLoggedIn => dispatch(setLoggedIn(isLoggedIn)),
    setUserData: userData => dispatch(setUserData(userData)),
    setUserLocale: userLocale => dispatch(setUserLocale(userLocale))
  };
}

export default connect(null, mapDispatchToProps)(SignOut);