import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
//import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';

import SignOut from './SignOut.js';

export default function DrawerList() {
  const { t, i18n } = useTranslation('drawer');
  const lang = i18n.language;
  return (
    <>
      <List>
        <ListItem component={RouterLink} to="/" button>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={t("dashboard")} />
        </ListItem>
        <ListItem component={RouterLink} to={"/" + lang + "/projects"} button>
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText primary={t("projects")} />
        </ListItem>
        <ListItem component={RouterLink} to={"/" + lang + "/account"} button>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary={t("myAccount")} />
        </ListItem>
        <ListItem component={RouterLink} to={"/" + lang + "/settings"} button>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={t("settings")} />
        </ListItem>
      </List>
      <Divider />
      <SignOut />
    </>
  );
}

/* export const secondaryListItems = (
  <div>
    <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <PowerSettingsNewIcon />
      </ListItemIcon>
      <ListItemText primary="Sign Out" />
    </ListItem>
  </div>
); */