import * as ACTIONS from "../constants/action-types.js";

export function setLoggedIn(payload) {
  return { type: ACTIONS.SET_LOGGED_IN, payload }
};

export function setUserData(payload) {
  return { type: ACTIONS.SET_USER_DATA, payload }
};

export function setUserLocale(payload) {
  return { type: ACTIONS.SET_USER_LOCALE, payload }
};

export function setPageData(payload) {
  return { type: ACTIONS.SET_PAGE_DATA, payload }
};